<template>
    <v-container fluid>
        <v-card>
            <Page-Header :title="$t('branches.branches')" icon="mdi-file-tree">
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                class="pa-5"
                :headers="headers"
                :items="branches"
                :search="search"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('search')"
                            single-line
                            hide-details
                            outlined
                            dense
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" persistent max-width="500px">
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-card>
                                    <v-toolbar color="primary darken-1" dark>
                                        <v-card-title>
                                            <span class="">{{
                                                (editedIndex === -1
                                                    ? $t("add")
                                                    : $t("edit")) +
                                                    " " +
                                                    $t("branches.branch")
                                            }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        outlined
                                                        dense
                                                        hide-details
                                                        hint="5 characters only!"
                                                        :rules="rules"
                                                        :counter="5"
                                                        v-model="
                                                            editedItem.branchCode
                                                        "
                                                        :label="
                                                            $t(
                                                                'branches.branchCode'
                                                            )
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        outlined
                                                        dense
                                                        hide-details
                                                        v-model="
                                                            editedItem.branchName
                                                        "
                                                        :rules="[
                                                            value =>
                                                                !!value ||
                                                                'Required.'
                                                        ]"
                                                        :label="
                                                            $t(
                                                                'branches.branchName'
                                                            )
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        outlined
                                                        dense
                                                        hide-details
                                                        v-model="
                                                            editedItem.branchUrl
                                                        "
                                                        :label="
                                                            $t(
                                                                'branches.branchUrl'
                                                            )
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            color="primary darken-1"
                                            class="white--text"
                                            :loading="loading"
                                            @click="save"
                                            :min-width="100"
                                        >
                                            <v-icon
                                                >mdi-content-save-outline</v-icon
                                            >
                                            {{ $t("save") }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            :loading="loading"
                                            text
                                            color="red"
                                            @click="close"
                                        >
                                            {{ $t("cancel") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                        <confirm-dialog
                            :openDialog="dialogDelete"
                            :onClicked="deleteItemConfirm"
                            :onClose="closeDelete"
                            toolBarColor="red darken-2"
                        ></confirm-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="item.branchCode != 'MAIN'" class="text-end">
                        <v-tooltip top v-if="isInRole(roles.branch.update)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="editItem(item)"
                                    v-bind="attrs"
                                    :loading="loading"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("edit") }}</span>
                        </v-tooltip>
                        <v-tooltip top v-if="isInRole(roles.branch.delete)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click="deleteItem(item)"
                                    v-bind="attrs"
                                    :loading="loading"
                                    color="red"
                                    v-on="on"
                                >
                                    <v-icon>
                                        mdi-delete-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("delete") }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            headers: [
                { text: this.$t("branches.branchCode"), value: "branchCode" },
                { text: this.$t("branches.branchName"), value: "branchName" },
                { text: this.$t("branches.branchUrl"), value: "branchUrl" },
                { text: "", value: "actions" }
            ],
            branches: [],
            editedItem: {
                branchCode: "",
                branchName: "",
                branchUrl: "",
                branchType: ""
            },
            defaultItem: {
                branchCode: "",
                branchName: "",
                branchUrl: "",
                branchType: ""
            },
            rules: [
                value => !!value || "Required.",
                value => (value && value.length <= 5) || "Max 5 characters"
            ]
        };
    },
    created() {
        if (!this.isInRole(this.roles.branch.branch))
            this.redirectUnauthorizedUsers();

        this.loading = true;
        this.getData();
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Branch" : "Edit Branch";
        },
        pageLength() {
            if (this.itemsPerPage == -1) {
                return 1;
            } else {
                var div = this.vouchers.length / this.itemsPerPage;
                return Number.isInteger(div) ? div : (div | 0) + 1;
            }
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.branches[this.editedIndex];

            axios
                .delete("Branches/Delete?id=" + deleteItem.branchGuid)
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }

                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("Branches/Get")
                    .then(response => {
                        this.branches = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            var val = this.$refs.form.validate();
            if (val) {
                if (this.editedIndex > -1) {
                    var updatedItem = this.branches[this.editedIndex];
                    updatedItem.branchCode = this.editedItem.branchCode;
                    updatedItem.branchName = this.editedItem.branchName;
                    updatedItem.branchUrl = this.editedItem.branchUrl;

                    axios
                        .post("Branches/Update", updatedItem)
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.close();
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }

                            axios.get("Accounts/GetGroups").then(response => {
                                this.groups = response.data.data;

                                this.getData();
                            });
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.getData();
                            console.log(e);
                        });
                    Object.assign(
                        this.branches[this.editedIndex],
                        this.editedItem
                    );
                } else {
                    axios
                        .post("Branches/Add", this.editedItem)
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.close();
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }

                            axios.get("Accounts/GetGroups").then(response => {
                                this.groups = response.data.data;
                                this.getData();
                            });
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.getData();
                            console.log(e);
                        });
                }
            }
        }
    }
};
</script>
